import { SESSION_COOKIE_NAME } from '~/utils/cookies'

/**
 * Retrieves the current active session, if it exists, from the browser's cookies
 *
 * @return {*}  {(string | undefined)} The session retrieved from the browser's cookies
 */
export const getSessionCookie = (): string | undefined =>
  document.cookie
    .split('; ')
    .find(row => row.startsWith(`${SESSION_COOKIE_NAME}=`))
    ?.split('=')[1]
