import '~/styles/globals.css'
import React from 'react'
import { StyledEngineProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { StoreProvider } from '~/components/providers/stores'
import { TemplateProvider } from '~/components/providers/template'
import type { AppProps as NextAppProps } from 'next/app'
import { CardModel } from '~/services/card'
import type { CardStoreState } from '~/components/providers/stores/card'
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter'
import { SessionProvider } from '~/components/providers/session'
import type { BasePageProps } from '~/middleware/ssr/utils/types'

interface PageProps {
  card?: CardModel
  session?: BasePageProps['session']
  template?: BasePageProps['template']
}

interface AppProps extends NextAppProps<PageProps> {
  pageProps: PageProps
}

export default function App(props: AppProps) {
  const { Component, pageProps } = props

  const initialStoreState: CardStoreState | undefined = pageProps.card
    ? {
        card: new CardModel(pageProps.card),
      }
    : undefined

  return (
    <StyledEngineProvider injectFirst>
      <AppCacheProvider {...props}>
        <SessionProvider session={pageProps.session}>
          <TemplateProvider template={pageProps.template}>
            <StoreProvider initialState={initialStoreState}>
              <CssBaseline />
              <Component {...pageProps} />
            </StoreProvider>
          </TemplateProvider>
        </SessionProvider>
      </AppCacheProvider>
    </StyledEngineProvider>
  )
}
