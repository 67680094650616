import type { FontFamily } from '~/theme/templates/fonts'

export const SofiaProRegular: FontFamily = {
  name: 'SofiaPro',
  weight: 400,
  format: 'opentype',
  url: '/fonts/SofiaProRegular.otf',
}

export const SofiaProBold: FontFamily = {
  name: 'SofiaPro',
  weight: 700,
  format: 'opentype',
  url: '/fonts/SofiaProBold.otf',
}
