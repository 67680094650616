import type { UserAuthorizationDetails } from '~/services/authorization'

export interface Session extends UserAuthorizationDetails {
  user_id: string
  brand_id: string
  token: string
  expires: Date
}

export const SESSION_COOKIE_NAME = 'session'

export const prepareSessionForClient = (
  session: Session
): Omit<Session, 'token'> => {
  const { token: _, ...clientSession } = session
  return clientSession
}
