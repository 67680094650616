import type { FontFamily } from '~/theme/templates/fonts'

export const GilroyRegular: FontFamily = {
  name: 'Gilroy',
  weight: 400,
  format: 'woff',
  url: '/fonts/Gilroy-Regular.woff',
}

export const GilroyBold: FontFamily = {
  name: 'Gilroy',
  weight: 700,
  format: 'woff',
  url: '/fonts/Gilroy-Bold.woff',
}
