import type { Theme, ThemeOptions } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import type { Template } from '~/theme/templates/types'

export const baseTheme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  typography: {
    h1: {
      fontSize: 40,
    },
    h2: {
      fontSize: 32,
    },
    h3: {
      fontSize: 24,
    },
    subtitle1: {
      fontSize: 16,
    },
    subtitle2: {
      fontSize: 14,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    overline: {
      fontSize: 12,
    },
    caption: {
      fontSize: 12,
    },
  },
}

export const getTheme = (template: Template): Theme =>
  createTheme({
    ...baseTheme,
    ...template.theme,
    components: {
      ...template.theme.components,
    },
  })
