import {
  createContext,
  useContext,
  type FunctionComponent,
  type ReactNode,
} from 'react'
import type { BrandProgramFeatures } from '~/services/authorization'
import { BrandProgram } from '~/services/dto/ts/brand'
import type { UserInformationResponse } from '~/services/dto/ts/user'
import type { Session } from '~/utils/cookies'

export type ClientSession = Omit<Session, 'token'>

class SessionContext implements ClientSession {
  constructor(
    public user_id: string,
    public brand_id: string,
    public expires: Date,
    public role: UserInformationResponse.role | null,
    public brand_program_type: BrandProgram.program_type,
    public brand_program_features: BrandProgramFeatures
  ) {}

  static fromSession(session: ClientSession) {
    return new SessionContext(
      session.user_id,
      session.brand_id,
      session.expires,
      session.role,
      session.brand_program_type,
      session.brand_program_features
    )
  }

  get isBrandConsumer() {
    return this.brand_program_type === BrandProgram.program_type.CONSUMER
  }

  get isBrandSMB() {
    return this.brand_program_type === BrandProgram.program_type.SMB
  }
}

const SessionCtx = createContext<SessionContext | null>(null)

export const SessionProvider: FunctionComponent<{
  session?: ClientSession
  children: ReactNode
}> = ({ session, children }) => {
  if (!session) {
    return children
  }
  return (
    <SessionCtx.Provider value={SessionContext.fromSession(session)}>
      {children}
    </SessionCtx.Provider>
  )
}

export const useSession = (): SessionContext => {
  const context = useContext(SessionCtx)
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider')
  }
  return context
}
