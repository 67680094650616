import axios, { AxiosError } from 'axios'
import logger from '~/utils/logger'
import { getSessionCookie } from '~/utils/cookies.client'
import type { Session } from '~/utils/cookies'

/**
 * Frontend API client used to interact with our Power APIs.
 */
export const frontendApiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
})

frontendApiClient.interceptors.request.use(config => {
  const sessionCookie = getSessionCookie()

  // If a session doesn't exist then a request like this shouldn't have been made in the first place
  if (sessionCookie === undefined) {
    window.location.assign('/session/missing')
  } else {
    const session = JSON.parse(
      Buffer.from(sessionCookie, 'base64').toString('utf8')
    ) as Partial<Session>

    // If a token isn't embedded in the session then the session was likely malformed and there's an issue somewhere else
    if (session.token === undefined) {
      logger.warn(
        `No token found in the current session for user id: ${session.user_id}, brand id ${session.brand_id}`
      )

      window.location.assign('/session/missing')
    }

    // Retrieve the access token embedded in the session and use it to authorize the request
    config.headers.setAuthorization(`Bearer ${session.token}`)
  }

  return config
})

frontendApiClient.interceptors.response.use(
  res => res,
  error => {
    const isAxiosError = error instanceof AxiosError

    // We should never have a Power API request get kicked back with a 401 unless the user's session has
    // become expired/invalid.
    if (isAxiosError && error.response?.status === 401) {
      window.location.assign('/session/missing')
    }

    // Log errors for any request that fails on the server, as these are never expected and should always
    // be actioned
    if (!isAxiosError || (error.status && error.status >= 500)) {
      logger.error(error as Error)
    }
    return Promise.reject(error)
  }
)
