var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0ea6f965af54bc444f2dfd04c2d69fd96b546084"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init } from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_RELEASE = process.env.BUILD_ID || ''
const NEXT_PUBLIC_ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || ''

init({
  dsn: SENTRY_DSN,
  release: SENTRY_RELEASE,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  tracesSampler: ({ parentSampled, transactionContext }) => {
    // Always maintain a parent's trace decision to ensure we don't have partial samples
    if (parentSampled !== undefined) return parentSampled
    // Don't trace synthetic monitoring checks coming from Grafana
    else if (
      transactionContext.metadata.request?.headers['user-agent']?.includes(
        'synthetic-monitoring-agent'
      )
    )
      return false
    // Don't trace in non-deployed environments
    else if (['development', 'test'].includes(NEXT_PUBLIC_ENVIRONMENT))
      return false
    // Since the application flow and card management pages are hit most often we can sample less of them
    else if (['/', '/management/[card_id]'].includes(transactionContext.name))
      return 0.25
    else return 0.5
  },
})
