import { ThemeProvider } from '@mui/material/styles'
import type { FunctionComponent, ReactNode } from 'react'
import { useContext, createContext } from 'react'
import { getTheme } from '~/theme'
import { PreloadFontHead } from '~/components/preload-font-head'
import type { Template } from '~/theme/templates/types'
import { PROGRAM_STATUS } from '~/theme/templates/types'

interface Props {
  children: ReactNode
  template?: Template
}

interface TemplateContext {
  template: Template
}

const baseTemplate: Template = {
  copy: {
    productName: '',
    brandName: '',
    cardName: '',
    bankPartner: '',
    subdomain: '',
    email: '',
    links: {
      homepage: '',
      legal: {
        loyaltyProgram: '',
        autopayAgreement: '',
        cardholderAgreement: '',
        ecommDisclosure: '',
        autodialerAuth: '',
        creditReportAuth: '',
        welcomeBonus: '',
      },
    },
    images: {
      logo: {
        src: '',
        width: 0,
        height: 0,
      },
      cardFrontSrc: '',
    },
    application: {
      title: [''],
      incentives: [{ title: '', copy: '' }],
      marginLow: 0,
      marginHigh: 0,
      feesRows: [{ title: '', explanation: '' }],
    },
    rewards: [],

    phoneNumber: '',
    offers: [],
    benefits: [],
  },
  gradient: '',
  cardOrientation: 'horizontal',
  theme: {},
  programStatus: PROGRAM_STATUS.Active,
  hiddenPaths: [],
}

const TemplateContext = createContext<TemplateContext>({
  template: baseTemplate,
})

export const TemplateProvider: FunctionComponent<Props> = ({
  children,
  template: brandedTemplate,
}) => {
  const template = brandedTemplate || baseTemplate
  const theme = getTheme(template)

  return (
    <>
      <PreloadFontHead template={template} />
      <TemplateContext.Provider value={{ template }}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </TemplateContext.Provider>
    </>
  )
}

export const useTemplate = (): TemplateContext => {
  return useContext(TemplateContext)
}
