import type { FontFamily } from '~/theme/templates/fonts'

export const MulishRegular: FontFamily = {
  name: 'Mulish',
  weight: 400,
  format: 'truetype',
  url: '/fonts/Mulish-Regular.ttf',
}

export const MulishBold: FontFamily = {
  name: 'Mulish',
  weight: 700,
  format: 'truetype',
  url: '/fonts/Mulish-Bold.ttf',
}
